import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useAuth } from '../../services/AuthProvider/AuthProvider'
import useApi from '../../api/apiProvider'
import { activateAccountApi, getCurrentAccountApi } from '../../api/account.api'
import { EMBEDDED_LOGIN_URL, LOGIN_CALLBACK_URL } from '../../config/config'
import { useSnackbar } from 'notistack'
import syncPayment from './syncPayment'
import syncAccount from './syncAccount'
import syncAddress from './syncAddress'
import syncOrder from './syncOrder'
import syncPhotoBank from './syncPhotoBank'

import { logger } from '../../services/logger'
import { addressManager } from '../../services/AddressManager'
import { countryManager } from '../../services/CountryManager'
import storageManager from '../../services/StorageManager'
import { IS_MIXTILES_PLUS_PURCHASE_REFRESH } from '../../App.consts'
import syncPhotoBook from './syncPhotoBook'
import { isClient } from 'utils/runtimeUtils'
import useOnMount from 'hooks/useOnMount'

function logInitAccountError(error) {
  logger.error('Error initializing account', error)
}

export const CurrentAccountContext = createContext()

CurrentAccountContext.displayName = 'CurrentAccountContext'

export function useAccount() {
  const context = useContext(CurrentAccountContext)

  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountProvider')
  }

  return context
}

export function withAccount(Component) {
  return React.forwardRef((props, ref) => (
    <CurrentAccountContext.Consumer>
      {(contexts) => <Component {...props} ref={ref} account={contexts} />}
    </CurrentAccountContext.Consumer>
  ))
}

export function AccountProvider({ children }) {
  const { isAuthenticated, isLoading: auth0Loading, logout } = useAuth()

  const isMixtilesPlusLoginAfterPurchase = useMemo(
    () =>
      isClient()
        ? !!storageManager.get(IS_MIXTILES_PLUS_PURCHASE_REFRESH)
        : false,
    []
  )
  const isBookInitialized = useRef(false)

  useOnMount(() => {
    storageManager.set(IS_MIXTILES_PLUS_PURCHASE_REFRESH, false)
  })

  const api = useApi()
  const [isLoading, setIsLoading] = useState(true)
  const [account, setAccount] = useState()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (auth0Loading) return

    // If we are in the login callback url we will skip the account because we might be in the middle
    // of account creation and getCurrentAccount will return account not found
    if (
      window.location.pathname === LOGIN_CALLBACK_URL ||
      window.location.pathname === EMBEDDED_LOGIN_URL
    ) {
      setIsLoading(false)
      return
    }

    if (!account) {
      initAccount()
    }
  }, [isAuthenticated, auth0Loading, account])

  const initAccount = useCallback(async () => {
    try {
      if (isAuthenticated) {
        let currentAccount
        try {
          // Try to get the current account
          currentAccount = await api.call(
            getCurrentAccountApi(countryManager.getCountry())
          )
        } catch (error) {
          logInitAccountError(error)
          logout()
          return
        }

        if (currentAccount) {
          if (currentAccount.isVerified && !currentAccount.migrationDone) {
            const activatedAccount = await api.call(activateAccountApi())
            setAccount(activatedAccount)
          } else {
            setAccount(currentAccount)
          }

          // We override remote account data with local if local exists and different
          // we are not waiting since we don't want to wait before showing something it can be loaded async
          syncPayment(api, currentAccount)
          syncAddress(api, currentAccount)
          syncPhotoBank(currentAccount.photoBank?.photos)
          if (!isMixtilesPlusLoginAfterPurchase) {
            syncOrder(api, currentAccount, enqueueSnackbar)
            if (!isBookInitialized.current) {
              syncPhotoBook(
                currentAccount.photoBank?.photos,
                currentAccount.orderDraft?.photoBook
              )
              isBookInitialized.current = true
            }
          }
          syncAccount(currentAccount)
          return currentAccount
        }
      } else {
        addressManager.removeAddressIfNotSupported()
      }
    } catch (error) {
      logInitAccountError(error)
    } finally {
      setIsLoading(false)
    }
  }, [
    isAuthenticated,
    auth0Loading,
    account,
    api.call,
    isMixtilesPlusLoginAfterPurchase,
  ])

  const value = {
    isLoading,
    isAuthenticated,
    account,
    reloadAccount: initAccount,
    isMixtilesPlusLoginAfterPurchase,
  }

  return (
    <CurrentAccountContext.Provider value={value}>
      {children}
    </CurrentAccountContext.Provider>
  )
}
