import React, { createContext, useCallback, useContext, useEffect } from 'react'
import { mixtilesAxios } from '../utils/ApiUtils'
import { getAuthRequestConfig } from '../services/LoginUtills'
import { Subject } from 'rxjs'
import { useAuth } from '../services/AuthProvider/AuthProvider'
import { logger } from '../services/logger'

const ApiContext = createContext()

ApiContext.displayName = 'ApiContext'

export const createApiFunction = (
  method,
  path,
  params = null,
  requiresAuthentication = false
) => ({
  method,
  path,
  params,
  requiresAuthentication,
})

export const API_METHODS = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
}

export default function useApi() {
  const context = useContext(ApiContext)

  if (context === undefined) {
    throw new Error('useApi must be used within a apiContextProvider')
  }

  return context
}

export function withApi(Component) {
  return function Comp(props) {
    return (
      <ApiContext.Consumer>
        {(contexts) => <Component {...props} api={contexts} />}
      </ApiContext.Consumer>
    )
  }
}

const apiSubject = { sub: new Subject() }

// We need this so we can trigger api function from everywhere including "managers"
// that are not react components since they can't use the context
export const triggerApi = (apiFunc) => {
  apiSubject.sub.next(apiFunc)
}

export function ApiProvider({ children }) {
  // We currently have two authentication providers that login in the user with Auth0.
  // Auth0Provider is a universal login mechanism package provided by Auth0,
  // Whereas AuthProvider is an embedded login mechanism we developed.
  // Therefore, when accessing the api with token, we check whether either provided has authenticated and if
  // so we use the access token provided by it.
  // This needs to be changed in the future as we move to the embedded login flow
  const { isAuthenticated, getAccessToken } = useAuth()

  useEffect(() => {
    // This is done to make sure the latest subscribe is with the most updated isAuthenticated flag
    if (apiSubject.sub.observers.length > 0) {
      apiSubject.sub.unsubscribe()
      apiSubject.sub = new Subject()
    }

    apiSubject.sub.subscribe(apiTriggered)
  }, [isAuthenticated])

  const apiTriggered = useCallback(
    async (apiFunc) => {
      await call(apiFunc).catch((e) => logger.error('API triggered error', e))
    },
    [isAuthenticated]
  )

  const call = useCallback(
    async ({
      method,
      path,
      params,
      headers,
      requiresAuthentication = false,
    }) => {
      if (requiresAuthentication && !isAuthenticated) {
        return
      }
      const token = isAuthenticated ? await getAccessToken() : null

      const requestHeaders = { ...headers, ...getAuthRequestConfig(token) }

      const request = {
        [API_METHODS.POST]: post,
        [API_METHODS.GET]: get,
        [API_METHODS.PUT]: put,
      }

      return request[method]({ path, params, headers: requestHeaders })
    },
    [isAuthenticated, getAccessToken]
  )

  const post = async ({ path, params, headers }) => {
    const response = await mixtilesAxios.post(path, params, headers)
    return response.data
  }

  const put = async ({ path, params, headers }) => {
    const response = await mixtilesAxios.put(path, params, headers)
    return response.data
  }

  const get = async ({ path, params, headers }) => {
    const requestConfig = {
      ...headers,
      params,
    }
    const response = await mixtilesAxios.get(path, requestConfig)
    return response.data
  }

  return (
    <ApiContext.Provider
      value={{
        call,
        isAuthenticated,
        getToken: getAccessToken,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
