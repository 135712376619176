import { type RemotePhoto } from '../PhotoBank/PhotoBank.types'
import { type SerializedPhotoBook } from '../../services/PhotoBook/photoBookDTO.types'
import {
  deserializeCover,
  deserializePages,
} from '../../services/PhotoBook/photoBookDTO'
import storageManager from '../../services/StorageManager'
import { ACCOUNT_PHOTO_BOOK_STORAGE_KEY } from '../PhotoBook/PhotoBook.consts'
import { getStorablePhotoBook } from '../../services/PhotoBook/photoBookSaver'

const syncPhotoBook = (
  accountPhotos: RemotePhoto[] | undefined = [],
  photoBook: SerializedPhotoBook | null
) => {
  if (!photoBook) {
    return
  }

  const pages = deserializePages(photoBook.pages, accountPhotos)
  const cover = deserializeCover(photoBook.cover, accountPhotos)

  const bookSize = photoBook.bookSize

  if (cover) {
    const storedPhotoBook = getStorablePhotoBook(cover, pages, bookSize)
    storageManager.set(ACCOUNT_PHOTO_BOOK_STORAGE_KEY, storedPhotoBook)
  }
}

export default syncPhotoBook
