import { type CheckoutSummaryProduct } from '../../components/Pricing/types/checkout.types'
import {
  type BookSize,
  ProductIds,
  type TILE_SIZES,
} from '@mixtiles/web-backend-shared'
import {
  type Cover,
  type Page,
  type PagePhoto,
  PageSide,
} from '../../components/PhotoBook/PhotoBookProvider/PhotoBook.types'
import { DEFAULT_PHOTO_RATIO } from './PhotoBook.consts'
import { isBlankPageSide } from './photoBookUtils'
import { type RemotePhoto } from '../../components/PhotoBank/PhotoBank.types'
import {
  type SerializedCover,
  type SerializedPhoto,
  type SerializedPhotoBook,
} from './photoBookDTO.types'
import { DefaultTextStyle } from '../../components/PhotoBook/CoverFontSelector/coverFonts'

// Format PhotoBook data for the products which are sent in /checkout-summary
export function serializePhotoBooksToCheckoutProducts({
  bookSize,
  quantity,
}: {
  bookSize: BookSize
  quantity: number
}): CheckoutSummaryProduct[] {
  return [
    {
      id: ProductIds.SKU_TILES,
      pricingProduct: {
        tileSize: bookSize as unknown as TILE_SIZES,
      },
      quantity,
    },
  ]
}

function serializePhotoBookPhoto(
  photo: PagePhoto,
  photoData: RemotePhoto
): SerializedPhoto {
  // Extract the crop params with aspect ratio 1 and covert them to absolute values
  const { originalUrl, width, height, uploadedAt } = photoData
  const relativeCropParams = photo.cropParams[DEFAULT_PHOTO_RATIO]
  const cropParams = {
    x: Math.floor(relativeCropParams.x * width),
    y: Math.floor(relativeCropParams.y * height),
    width: Math.floor(relativeCropParams.width * width),
    height: Math.floor(relativeCropParams.height * height),
    cropType: relativeCropParams.cropType,
  }

  return {
    url: originalUrl,
    width,
    height,
    cropParams,
    dateTaken: photo.dateTaken,
    uploadedAt,
  }
}

function serializeCover(
  cover: Cover,
  remotePhotos: RemotePhoto[]
): SerializedCover {
  const remotePhoto = remotePhotos.find((p) => p?.uid === cover.photo.uid)
  return {
    caption: {
      text: cover?.caption?.text || '',
      style: cover?.caption?.style || DefaultTextStyle,
      fontHeight: cover?.caption?.fontHeight || 0,
    },
    photo: remotePhoto
      ? serializePhotoBookPhoto(cover.photo, remotePhoto)
      : null,
  }
}

// The web and backend store the pages differently. In the web, each page is consisted of a front and back side (because
// it's required for the rendering of the visual photo book), but the backend doesn't care about it and only stores the pages
// as one list. Therefore, we need to unite the front and back pages before sending them to the backend. In addition to
// it, we only send the relevant parameters to the backend.
function serializePages(pages: Page[], remotePhotos: RemotePhoto[]) {
  const allPages = pages
    .map((page) => [page.front, page.back])
    .flat()
    .filter((page) => !isBlankPageSide(page))
  return allPages.map((page) => {
    return {
      photos: page.photos.map((photo) => {
        const remotePhoto = remotePhotos.find((p) => p?.uid === photo.uid)
        return remotePhoto ? serializePhotoBookPhoto(photo, remotePhoto) : null
      }),
    }
  })
}

export function deserializeCover(
  cover: SerializedCover,
  remotePhotos: RemotePhoto[]
): Cover | null {
  if (!cover.photo) {
    return null
  }

  const photo = deserializePhotoBookPhoto(cover.photo, remotePhotos)

  return photo
    ? {
        photo,
        caption: {
          text: cover.caption.text,
          style: cover.caption.style || DefaultTextStyle,
          fontHeight: cover.caption.fontHeight || 0,
        },
        isFrontOpen: false,
        isBackOpen: false,
      }
    : null
}

function deserializePhotoBookPhoto(
  formattedPhoto: SerializedPhoto,
  remotePhotos: RemotePhoto[]
): PagePhoto | null {
  const rightPhoto = remotePhotos.find(
    (p) => p.originalUrl === formattedPhoto.url
  )
  if (!rightPhoto) {
    return null
  }

  return {
    uid: rightPhoto.uid,
    dateTaken: formattedPhoto.dateTaken,
    cropParams: {
      [DEFAULT_PHOTO_RATIO]: {
        x: formattedPhoto.cropParams.x / formattedPhoto.width,
        y: formattedPhoto.cropParams.y / formattedPhoto.height,
        width: formattedPhoto.cropParams.width / formattedPhoto.width,
        height: formattedPhoto.cropParams.height / formattedPhoto.height,
        cropType: formattedPhoto.cropParams.cropType,
      },
    },
  }
}

export function deserializePages(
  formattedPages: { photos: (SerializedPhoto | null)[] }[],
  remotePhotos: RemotePhoto[]
): Page[] {
  const unformattedPages: Page[] = []

  for (let i = 0; i < formattedPages.length; i += 2) {
    const frontPage = formattedPages[i]
    const backPage = formattedPages[i + 1]

    const unformattedFront = {
      photos: frontPage.photos
        .map((formattedPhoto) =>
          formattedPhoto
            ? deserializePhotoBookPhoto(formattedPhoto, remotePhotos)
            : null
        )
        .filter((photo) => photo !== null) as PagePhoto[],
    }

    const unformattedBack = backPage
      ? {
          photos: backPage.photos
            .map((formattedPhoto) =>
              formattedPhoto
                ? deserializePhotoBookPhoto(formattedPhoto, remotePhotos)
                : null
            )
            .filter((photo) => photo !== null) as PagePhoto[],
        }
      : {
          photos: [],
        }

    unformattedPages.push({
      front: unformattedFront,
      back: unformattedBack,
      pageSide: PageSide.FRONT,
    })
  }

  return unformattedPages
}

// Format PhotoBook data for the products which are sent in /order
export function serializePhotoBooksMetadata({
  bookSize,
  cover,
  pages,
  remotePhotos,
}: {
  bookSize: BookSize
  cover: Cover
  pages: Page[]
  remotePhotos: RemotePhoto[]
}): SerializedPhotoBook {
  return {
    bookSize,
    cover: serializeCover(cover, remotePhotos),
    pages: serializePages(pages, remotePhotos),
  }
}
