import React, { type PropsWithChildren, useMemo } from 'react'
import { PRODUCT_TYPES, productTypeState } from './ProductTypeState'
import { useLocation } from '@remix-run/react'
import {
  BUSINESS_PAGE_URL,
  CANVAS_PRODUCT_PAGE,
  COLLAGE_PRODUCT_PAGE,
  DISCOVERY_URL,
  GIFT_CARD,
  JUMBLE_URL_PREFIX,
  MIXTILES_ART_URL,
  PHOTO_BOOK_URL,
  PHOTO_STYLER_URL,
  PHOTO_WALL_URL,
} from '../config/config'

type ProductTypeContextType = {
  productType: PRODUCT_TYPES
}

const ProductTypeContext = React.createContext<
  ProductTypeContextType | undefined
>(undefined)

export default function ProductTypeProvider({ children }: PropsWithChildren) {
  const { pathname } = useLocation()
  const productType = useMemo<PRODUCT_TYPES>(() => {
    const newProductType = getProductType(pathname) || PRODUCT_TYPES.CLASSIC
    productTypeState.setProductType(newProductType)
    return newProductType
  }, [pathname])

  return (
    <ProductTypeContext.Provider value={{ productType }}>
      {children}
    </ProductTypeContext.Provider>
  )
}

export function useProductType() {
  const context = React.useContext(ProductTypeContext)
  if (!context) {
    throw new Error('useProductType must be used within a ProductTypeProvider')
  }

  return context
}

export function withProductType(Component: any) {
  return React.forwardRef((props, ref) => (
    <ProductTypeContext.Consumer>
      {(context) => <Component {...props} ref={ref} {...context} />}
    </ProductTypeContext.Consumer>
  ))
}

export function getProductType(pathname: string) {
  if (
    pathname === '/' ||
    pathname.startsWith(PHOTO_STYLER_URL) ||
    pathname.startsWith(DISCOVERY_URL) ||
    pathname.startsWith(PHOTO_WALL_URL) ||
    pathname.startsWith(JUMBLE_URL_PREFIX) ||
    pathname.startsWith(CANVAS_PRODUCT_PAGE) ||
    pathname.startsWith(COLLAGE_PRODUCT_PAGE)
  ) {
    return PRODUCT_TYPES.CLASSIC
  }
  if (pathname === GIFT_CARD) {
    return PRODUCT_TYPES.GIFT_CARD
  }
  if (pathname.startsWith(BUSINESS_PAGE_URL)) {
    return PRODUCT_TYPES.BUSINESS
  }
  if (pathname.startsWith(PHOTO_BOOK_URL)) {
    return PRODUCT_TYPES.PHOTO_BOOK
  }
  if (pathname.startsWith(MIXTILES_ART_URL)) {
    return PRODUCT_TYPES.ART
  }
  return null
}
